import React from "react";

const Pagination = ({ currentPage, totalPage, onPageChange }) => {
  const pageNumbers = [];

  for (let i = 1; i <= totalPage; i++) {
    pageNumbers.push(i);
  }

  const showEllipsis = totalPage > 5;

  return (
    <>
      {showEllipsis && currentPage > 3 && (
        <button onClick={() => onPageChange(1)}>1</button>
      )}
      {showEllipsis && currentPage > 4 && <span>...</span>}

      {pageNumbers.map((number) => {
        const isCurrentPage = number === currentPage;
        const shouldDisplay =
          (!showEllipsis && number <= 5) ||
          (showEllipsis &&
            ((number === 1 && currentPage <= 3) ||
              (number === totalPage && currentPage >= totalPage - 2) ||
              (number >= currentPage - 1 && number <= currentPage + 1)));

        return shouldDisplay ? (
          <button
            key={number}
            onClick={() => onPageChange(number)}
            className={isCurrentPage ? "active" : ""}
          >
            {number}
          </button>
        ) : null;
      })}

      {showEllipsis && currentPage < totalPage - 2 && <span>...</span>}
      {showEllipsis && currentPage < totalPage - 3 && (
        <button onClick={() => onPageChange(totalPage)}>{totalPage}</button>
      )}
    </>
  );
};

export default Pagination;
