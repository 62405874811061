import React, { useCallback, useEffect, useState } from "react";
import "./categories.css";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { IoCloseCircleOutline } from "react-icons/io5";
import upCategoryImg from "../../../components/img/category_img.webp";
import { MdEditSquare } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const Categories = () => {
  const { handleSubmit, control, setValue, getValues, reset } = useForm();
  const [isDisabledSubmit, setDisabledSubmit] = useState(false);
  const [categories, setCategories] = useState([]);
  const [mainSiteUrl, setMainSiteUrl] = useState("");

  const navigate = useNavigate();

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;
  const onBlurCategoryName = () => {
    const firstNameValue = getValues("category_name");
    const trimmedLastName = firstNameValue.trim().replace(/^-+|-+$/g, "");
    const modifiedFirstName = trimmedLastName
      .replace(/\s+/g, "-")
      .toLowerCase();
    setValue("category_url", modifiedFirstName);
  };

  const onBlurCategoryUrl = () => {
    const lastNameValue = getValues("category_url");
    const trimmedLastName = lastNameValue.trim().replace(/^-+|-+$/g, "");
    const modifiedLastName = trimmedLastName.replace(/\s+/g, "-").toLowerCase();
    setValue("category_url", modifiedLastName);
  };

  const fetchSettings = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/settings.php`,
        formDataPages
      );

      setMainSiteUrl(response.data.url_website);
    } catch (error) {
      console.error("Error:", error);
    }
  }, [validation, LinkLikeBachend]);

  const fetchCategories = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/categories.php`,
        formDataPages
      );

      setCategories(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, [validation, LinkLikeBachend]);

  useEffect(() => {
    fetchCategories();
    fetchSettings();
  }, [fetchSettings, fetchCategories]);

  const onSubmitCategories = async (data) => {
    setDisabledSubmit(true);

    const FormDataSubmit = new FormData();
    FormDataSubmit.append("validation", validation);

    Object.entries(data).forEach(([key, value]) => {
      FormDataSubmit.append(key, value);
    });

    try {
      const response = await axios.post(
        `${LinkLikeBachend}create/categories.php`,
        FormDataSubmit
      );

      setDisabledSubmit(false);

      if (response.data == "successfully") {
        toast.success("تم الاضافة بنجاح");
        setDisabledSubmit(false);

        reset();

        let category_img = document.querySelector("#category_img");
        category_img.value = "";

        fetchCategories();
      } else if (response.data == "redundant") {
        toast.error("رابط التصنيف موجود بالفعل");
        setDisabledSubmit(false);
      } else {
        toast.error("حدث خطأ عند الاضافة");
        console.log(response.data);
        setDisabledSubmit(false);
      }
    } catch (error) {
      setDisabledSubmit(false);
      console.error("Error:", error);
      toast.error("حدث خطأ عند الاضافة");
    }
  };

  const handleDeleteCategory = async (idCategory) => {
    setDisabledSubmit(true);

    const FormDataSubmit = new FormData();
    FormDataSubmit.append("validation", validation);
    FormDataSubmit.append("idCategory", idCategory);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}delete/delete_categories.php`,
        FormDataSubmit
      );

      setDisabledSubmit(false);

      if (response.data == "successfully") {
        toast.success("تم الحذف بنجاح");
        setDisabledSubmit(false);

        fetchCategories();
      } else {
        toast.error("حدث خطأ الحذف");
        console.log(response.data);
        setDisabledSubmit(false);
      }
    } catch (error) {
      setDisabledSubmit(false);
      console.error("Error:", error);
      toast.error("حدث خطأ الحذف");
    }
  };

  const handleEditCategory = (idCategory) => {
    navigate(`/dashboard/edit-category/${idCategory}`);
  };

  // console.log(categories);

  return (
    <div className="categories">
      <form
        onSubmit={handleSubmit(onSubmitCategories)}
        encType="multipart/form-data"
      >
        <h2>اضافة تصنيف</h2>

        <Controller
          name="category_img"
          control={control}
          defaultValue=""
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <div className="category_img">
              <input
                required
                id="category_img"
                type="file"
                accept="image/*"
                onChange={(e) => {
                  onChange(e.target.files[0]);
                }}
                onBlur={onBlur}
                ref={ref}
              />
              {value ? (
                <img src={URL.createObjectURL(value)} alt="category_img" />
              ) : (
                <img src={upCategoryImg} alt="category_img" />
              )}
            </div>
          )}
        />

        <div>
          <label>اسم التصنيف</label>
          <Controller
            name="category_name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <input
                required
                type="text"
                placeholder="اسم التصنيف"
                {...field}
                onBlur={onBlurCategoryName}
              />
            )}
          />
        </div>

        <div>
          <label>رابط التصنيف</label>
          <Controller
            name="category_url"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <input
                required
                type="text"
                placeholder="رابط التصنيف"
                {...field}
                onBlur={onBlurCategoryUrl}
              />
            )}
          />
        </div>

        <button
          type="submit"
          disabled={isDisabledSubmit}
          style={{ opacity: isDisabledSubmit ? 0.3 : "" }}
        >
          حفظ
        </button>
      </form>

      <table>
        <thead>
          <tr>
            <th></th>
            <th>اسم التصنيف</th>
            <th>رابط التصنيف</th>
            <th>تاريخ الاضافة</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {categories?.map((category, i) => (
            <tr key={i}>
              <td>
                <img
                  src={`${LinkLikeBachend}${category.category_img}`}
                  alt={category.category_name}
                />
              </td>
              <td>{category.category_name}</td>
              <td>
                <a
                  href={`${mainSiteUrl}/categories?category=${category.category_url}`}
                  target="_blank"
                >
                  {category.category_url}
                </a>
              </td>
              <td>{category.date_add}</td>
              <td>
                <MdEditSquare onClick={() => handleEditCategory(category.id)} />
              </td>
              <td>
                <IoCloseCircleOutline
                  onClick={() => handleDeleteCategory(category.id)}
                  disabled={isDisabledSubmit}
                  style={{ opacity: isDisabledSubmit ? 0.3 : "" }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Categories;
