import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import "./adminContact.css";
import { IoCloseCircle } from "react-icons/io5";

const ViewContact = ({ close }) => {
  const [contactCount, setcontactCount] = useState(0);
  const [viewContact, setViewContact] = useState([]);
  const [isDisabledLoadMore, setDisabledLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDatacontactCount = useCallback(async () => {
    const formDataCount = new FormData();
    formDataCount.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/contact_count.php`,
        formDataCount
      );

      setcontactCount(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, [validation, LinkLikeBachend]);

  const fetchDataContact = useCallback(async () => {
    const formDataContact = new FormData();
    formDataContact.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/contact_us.php`,
        formDataContact
      );
      setViewContact(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, [validation, LinkLikeBachend]);

  const handleLoadMore = async () => {
    setDisabledLoadMore(true);

    const formDataViewcontactPage = new FormData();
    formDataViewcontactPage.append("validation", validation);
    formDataViewcontactPage.append("page", currentPage + 1);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/contact_us.php`,
        formDataViewcontactPage
      );

      setTimeout(() => {
        setViewContact((prevData) => prevData.concat(response.data));

        setDisabledLoadMore(false);

        setCurrentPage((prevPage) => prevPage + 1);
      }, 2000);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchDatacontactCount();
    fetchDataContact();
  }, []);

  const handelOpenMessage = () => {
    close(false);
  };

  return (
    <div className="fixed">
      <IoCloseCircle className="IoCloseCircle" onClick={handelOpenMessage} />

      <div className="viewContact">
        {viewContact ? (
          viewContact.length > 0 &&
          viewContact.map((item, i) => (
            <div className="contact_item" key={i}>
              <p>{item.date_contact}</p>
              <p>{item.name_contact}</p>
              <p>{item.email_contact}</p>
              <p>{item.title_contact}</p>
              <p>{item.message_contact}</p>
            </div>
          ))
        ) : (
          <p>لا يوجد بيانات لعرضها</p>
        )}
      </div>

      <div className="div_load_more">
        {viewContact &&
          viewContact.length > 0 &&
          parseInt(contactCount) !== viewContact.length && (
            <button
              className="button load_more"
              onClick={handleLoadMore}
              disabled={isDisabledLoadMore}
              style={{ opacity: isDisabledLoadMore ? 0.3 : 1 }}
            >
              {!isDisabledLoadMore ? "المزيد" : "جاري التحميل..."}
            </button>
          )}
      </div>
    </div>
  );
};

export default ViewContact;
