import Cookies from "js-cookie";
import { createContext, useContext, useState } from "react";

export const AuthAdmin = createContext(null);

export const ContextProvider = ({ children }) => {
  let cookies_loginAdmin = Cookies.get("loginAdmin")
    ? Cookies.get("loginAdmin")
    : null;

  const [admin, setAdmin] = useState(cookies_loginAdmin);

  const loginAdmin = (admin) => {
    Cookies.set("loginAdmin", admin, { expires: 1 });

    setAdmin(Cookies.get("loginAdmin"));
  };

  const logoutAdmin = () => {
    Cookies.remove("loginAdmin");

    window.location.reload();
  };

  return (
    <AuthAdmin.Provider value={{ admin, loginAdmin, logoutAdmin }}>
      {children}
    </AuthAdmin.Provider>
  );
};

export const useAuthAdmin = () => {
  return useContext(AuthAdmin);
};
