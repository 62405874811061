import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import LoginAdmin from "./admin/LoginAdmin";
import NotFound from "./components/notFound/NotFound";
import { ContextProvider } from "./components/context/AuthAdmin";
import Navbar from "./admin/navAdmin/Navbar";
import Dashboard from "./admin/dashboard/Dashboard";
import AdminDataPages from "./admin/pages/adminDataPages/AdminDataPages";
import AdminContact from "./admin/pages/adminContact/AdminContact";
import Categories from "./admin/pages/categories/Categories";
import AdminAddCoupon from "./admin/pages/adminAddCoupon/AdminAddCoupon";
import EditCategory from "./admin/pages/categories/editCategory/EditCategory";
import AdminCoupon from "./admin/pages/adminAddCoupon/AdminCoupon/AdminCoupon";
import AdminEditCoupon from "./admin/pages/adminAddCoupon/adminEditCoupon/AdminEditCoupon";
import Slider from "./admin/pages/slider/Slider";

const App = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <ContextProvider>
      <Routes>
        <Route path="/" element={<LoginAdmin />} />

        <Route path="/dashboard" element={<Navbar />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="admin-data-pages" element={<AdminDataPages />} />
          <Route path="admin-contact" element={<AdminContact />} />
          <Route path="categories" element={<Categories />} />
          <Route path="edit-category/:idCategory" element={<EditCategory />} />
          <Route path="admin-add-coupon" element={<AdminAddCoupon />} />
          <Route path="admin-coupon" element={<AdminCoupon />} />
          <Route path="edit-coupon/:couponId" element={<AdminEditCoupon />} />
          <Route path="slider" element={<Slider />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </ContextProvider>
  );
};

export default App;
