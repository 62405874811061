import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../adminAddCoupon.css";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import upCategoryImg from "../../../../components/img/category_img.webp";
import JoditEditor from "jodit-react";
import { IoClose } from "react-icons/io5";

const AdminEditCoupon = () => {
  const { couponId } = useParams();
  const [coupons, setCoupons] = useState({});
  const { handleSubmit, control, setValue, getValues } = useForm();
  const [isDisabledSubmit, setDisabledSubmit] = useState(false);
  const [categories, setCategories] = useState([]);
  const [couponCategories, setCouponCategories] = useState([]);
  const [editorConfigCoupon] = useState({
    placeholder: "",
    minHeight: "500",
  });

  const [keywords, setKeywords] = useState([]);
  const [keyword, setKeyword] = useState("");

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchCoupons = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);
    formDataPages.append("couponId", couponId);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/coupons.php`,
        formDataPages
      );

      setCoupons(response.data[0]);

      setValue("title", response.data[0].title);
      setValue("discount_percentage", response.data[0].discount_percentage);
      setValue("keywords", response.data[0].keywords);
      setValue("title_seo", response.data[0].title_seo);
      setValue("url_coupon", response.data[0].url_coupon);
      setValue("description_c", response.data[0].description_c);
      setValue("description_seo", response.data[0].description_seo);
      setValue("store_name", response.data[0].store_name);
      setValue("discount_code", response.data[0].discount_code);
      setValue("store_link", response.data[0].store_link);

      let keywords_db = response.data[0].keywords;

      const arraykeywords_db = keywords_db
        .split(",")
        .map((item) => item.trim());

      setKeywords(arraykeywords_db);

      setValue("most_viewed", response.data[0].most_viewed);
      setValue("chose_for_you", response.data[0].chose_for_you);
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  const fetchCategories = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/categories.php`,
        formDataPages
      );

      setCategories(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  const fetchCouponCategories = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);
    formDataPages.append("couponId", couponId);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/coupon_categories.php`,
        formDataPages
      );

      setCouponCategories(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  useEffect(() => {
    fetchCoupons();
    fetchCategories();
    fetchCouponCategories();
  }, [validation, LinkLikeBachend]);

  const onBlurCouponTitle = () => {
    const CouponTitleValue = getValues("title_seo");
    const trimmedLastName = CouponTitleValue.trim().replace(/^-+|-+$/g, "");
    const modifiedFirstName = trimmedLastName
      .replace(/\s+/g, "-")
      .toLowerCase();
    setValue("url_coupon", modifiedFirstName);
  };

  const onBlurCouponUrl = () => {
    const CouponUrlValue = getValues("url_coupon");
    const trimmedLastName = CouponUrlValue.trim().replace(/^-+|-+$/g, "");
    const modifiedLastName = trimmedLastName.replace(/\s+/g, "-").toLowerCase();
    setValue("url_coupon", modifiedLastName);
  };

  const mergedCategories = categories.map((category) => {
    const matchingCouponCategory = couponCategories.find(
      (couponCategory) => couponCategory.category_id == category.id
    );

    return {
      ...category,
      isChecked: !matchingCouponCategory,
    };
  });

  const handleInputChange = (e) => {
    setKeyword(e.target.value);
  };

  const handleAddKeywords = () => {
    if (keyword.includes(",")) {
      let uniqueKeywords = new Set(keywords);

      const splittedKeywords = keyword
        .split(",")
        .map((trimmedKeyword) => trimmedKeyword.trim());

      uniqueKeywords = new Set([...uniqueKeywords, ...splittedKeywords]);

      const uniqueKeywordsArray = Array.from(uniqueKeywords);

      setKeywords(uniqueKeywordsArray);

      setKeyword("");
    } else {
      if (!keywords.includes(keyword)) {
        setKeywords([...keywords, keyword]);

        setKeyword("");
      } else {
        setKeyword("");
      }
    }
  };

  const handelDeleteKeyword = (iKeyword) => {
    const updatedKeywords = keywords.filter((_, index) => index !== iKeyword);
    setKeywords(updatedKeywords);
  };

  const onSubmitCoupon = async (data) => {
    let editorConfigCoupon = document.querySelector("#editorConfigCoupon");

    if (editorConfigCoupon.value.length < 1) {
      return toast.error("الوصف فارغ");
    }

    let categoryIdElements = document.querySelectorAll(".categoryId");

    let atLeastOneChecked = false;

    categoryIdElements.forEach((category) => {
      if (category.checked) {
        atLeastOneChecked = true;
      }
    });

    if (!atLeastOneChecked) {
      return toast.error("يجب اختيار تصنيف");
    }

    // console.log(data);

    setDisabledSubmit(true);
    const FormDataSubmit = new FormData();
    FormDataSubmit.append("validation", validation);
    FormDataSubmit.append("newkeywords", keywords);
    FormDataSubmit.append("couponId", couponId);

    Object.entries(data).forEach(([key, value]) => {
      FormDataSubmit.append(key, value);
    });

    try {
      const response = await axios.post(
        `${LinkLikeBachend}update/coupon.php`,
        FormDataSubmit
      );

      setDisabledSubmit(false);

      // console.log(response.data);

      if (response.data == "successfully") {
        toast.success("تم التحديث بنجاح");
        setDisabledSubmit(false);

        let store_img = document.querySelector("#store_img");
        store_img.value = "";

        fetchCoupons();
        fetchCategories();
        fetchCouponCategories();
      } else if (response.data == "redundant") {
        toast.error("رابط الكوبون موجود بالفعل");
        setDisabledSubmit(false);
      } else {
        toast.error("حدث خطأ عند التحديث");
        console.log(response.data);
        setDisabledSubmit(false);
      }
    } catch (error) {
      setDisabledSubmit(false);
      console.error("Error:", error);
      toast.error("حدث خطأ عند التحديث");
    }
  };

  // console.log(coupons);
  // console.log(categories);
  // console.log(couponCategories);

  return (
    <div className="coupons">
      <form
        onSubmit={handleSubmit(onSubmitCoupon)}
        encType="multipart/form-data"
      >
        <h2>تحديث كوبون</h2>

        <Controller
          name="store_img"
          control={control}
          defaultValue=""
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <div className="store_img">
              <input
                id="store_img"
                type="file"
                accept="image/*"
                onChange={(e) => {
                  onChange(e.target.files[0]);
                }}
                onBlur={onBlur}
                ref={ref}
              />
              {value || coupons?.store_img ? (
                <img
                  src={
                    value
                      ? URL.createObjectURL(value)
                      : `${LinkLikeBachend}${coupons?.store_img}`
                  }
                  alt="store_img"
                />
              ) : (
                <img src={upCategoryImg} alt="store_img" />
              )}
            </div>
          )}
        />

        <div className="div">
          <b>عنوان</b>
          <Controller
            name="title"
            control={control}
            defaultValue={coupons?.store_img || ""}
            render={({ field }) => (
              <input required type="text" placeholder="عنوان" {...field} />
            )}
          />
        </div>

        <div className="div">
          <b>نسبة الخصم</b>
          <Controller
            name="discount_percentage"
            control={control}
            defaultValue=""
            render={({ field }) => <input required type="text" {...field} />}
          />
        </div>

        <div className="div">
          <b>عنوان SEO ينصح 50-60 حرفًا {}</b>
          <Controller
            name="title_seo"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <input
                required
                type="text"
                placeholder="عنوان SEO"
                {...field}
                onBlur={onBlurCouponTitle}
              />
            )}
          />
        </div>

        <div className="div">
          <b>وصف SEO ينصح 150 إلى 160 حرفًا</b>
          <Controller
            name="description_seo"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <textarea
                required
                type="text"
                placeholder="وصف SEO"
                {...field}
              ></textarea>
            )}
          />
        </div>

        <div className="div">
          <b>الكلمات المفتاحية</b>
          <div className="keywords">
            {keywords.length > 0 &&
              keywords.map((keyword, i) => (
                <p key={i}>
                  <span>
                    <IoClose onClick={() => handelDeleteKeyword(i)} />
                  </span>
                  {keyword}
                </p>
              ))}
          </div>
          <div className="keyword">
            <input
              type="text"
              name="keywordsUpdate"
              placeholder="اضافة الكلمات المفتاحية"
              value={keyword}
              onChange={handleInputChange}
            />
            <span onClick={handleAddKeywords}>اضافة</span>
          </div>
        </div>

        <div className="div">
          <b>رابط الكوبون ينصح 50-60 حرفًا</b>
          <Controller
            name="url_coupon"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <input
                required
                type="text"
                placeholder="رابط الكوبون"
                {...field}
                onBlur={onBlurCouponUrl}
              />
            )}
          />
        </div>

        <div className="div">
          <b>اسم المتجر</b>
          <Controller
            name="store_name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <input required type="text" placeholder="اسم المتجر" {...field} />
            )}
          />
        </div>

        <div className="div">
          <b>كود الخصم</b>
          <Controller
            name="discount_code"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <input required type="text" placeholder="كود الخصم" {...field} />
            )}
          />
        </div>

        <div className="div">
          <b>رابط المتجر</b>
          <Controller
            name="store_link"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <input required type="url" placeholder="رابط المتجر" {...field} />
            )}
          />
        </div>

        <div className="description_JoditEditor">
          <b>الوصف</b>
          <Controller
            name="description_c"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <JoditEditor
                required
                id="editorConfigCoupon"
                config={editorConfigCoupon}
                {...field}
              />
            )}
          />
        </div>

        <br />
        <br />

        <div className="div">
          <div className="check_categories">
            <div className="check_category">
              <Controller
                name="most_viewed"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <input
                    type="checkbox"
                    {...field}
                    checked={field.value == "1"}
                  />
                )}
              />

              <b>الاكثر مشاهدة</b>
            </div>

            <div className="check_category">
              <Controller
                name="chose_for_you"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <input
                    type="checkbox"
                    {...field}
                    checked={field.value == "1"}
                  />
                )}
              />

              <b>اخترنا لك</b>
            </div>

            <br />

            <b>التصنيف</b>
            {!isDisabledSubmit &&
              mergedCategories.length > 0 &&
              mergedCategories.map((category, i) => (
                <div className="check_category" key={i}>
                  <Controller
                    name={`categoryId_${category.id}`}
                    control={control}
                    defaultValue={category.isChecked}
                    render={({ field }) => (
                      <input
                        className="categoryId"
                        type="checkbox"
                        {...field}
                        checked={field.value == "1"}
                      />
                    )}
                  />

                  <p>{category.category_name}</p>
                </div>
              ))}
          </div>
        </div>

        <button
          className="submit"
          type="submit"
          disabled={isDisabledSubmit}
          style={{ opacity: isDisabledSubmit ? 0.3 : "" }}
        >
          حفظ
        </button>
      </form>
    </div>
  );
};

export default AdminEditCoupon;
