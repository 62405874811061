import React, { useCallback, useEffect, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuthAdmin } from "../../components/context/AuthAdmin";
import "./navbar.css";
import Margin from "./Margin";

const Navbar = () => {
  const AuthAdmin = useAuthAdmin();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("/dashboard");

  const handleLinkClick = (href) => {
    setActiveLink(href);
  };

  const handleAuthAdmin = useCallback(() => {
    if (AuthAdmin.admin == null && AuthAdmin.admin != "admin") {
      navigate("/", { replace: true });
    }

    handleLinkClick(location.pathname);

    const buttons = document.querySelectorAll(".dropdown-btn");

    buttons.forEach((button) => {
      button.addEventListener("click", (event) => {
        const parentDiv = button.parentNode;
        parentDiv.classList.add("active");
        event.stopPropagation();
      });
    });

    document.addEventListener("click", () => {
      document.querySelectorAll(".dropdown").forEach((element) => {
        element.classList.remove("active");
      });
    });
  }, [location.pathname]);

  useEffect(() => {
    handleAuthAdmin();
  }, [handleAuthAdmin]);

  const handleLogOut = () => {
    AuthAdmin.logoutAdmin();
  };

  const links_pages = [
    { href: "admin-coupon", label: "كوبون" },
    { href: "admin-add-coupon", label: "اضافة كوبون" },
    { href: "categories", label: "التصنيفات" },
  ];

  return (
    <>
      <header>
        {/* <!-- Menu --> */}
        <div className="full_dropdown">
          <div
            className={`dropdown ${
              activeLink === "/dashboard" ? "active" : ""
            }`}
          >
            <NavLink to="/dashboard" className="dashboard_home">
              الاعدادات
            </NavLink>
          </div>

          <div
            className={`dropdown ${
              activeLink === "/dashboard/admin-data-pages" ? "active" : ""
            }`}
          >
            <NavLink
              to="/dashboard/admin-data-pages"
              className="dashboard_home"
            >
              صفحات اساسية
            </NavLink>
          </div>

          <div
            className={`dropdown ${
              activeLink === "/dashboard/admin-contact" ? "active" : ""
            }`}
          >
            <NavLink to="/dashboard/admin-contact" className="dashboard_home">
              اتصل بنا
            </NavLink>
          </div>

          <div
            className={`dropdown ${
              activeLink === "/dashboard/slider" ? "active" : ""
            }`}
          >
            <NavLink to="/dashboard/slider" className="dashboard_home">
              سلايدر
            </NavLink>
          </div>

          <div className="dropdown">
            <button className="dropdown-btn">كوبونات الخصم</button>
            <div className="dropdown-content">
              {links_pages.map((link, i) => (
                <button
                  key={i}
                  className={activeLink === link.href ? "active" : ""}
                >
                  <NavLink
                    className="shortcut"
                    to={link.href}
                    onClick={() => handleLinkClick(link.href)}
                    aria-current={activeLink === link.href ? "page" : null}
                  >
                    {link.label}
                  </NavLink>
                </button>
              ))}
            </div>
          </div>
        </div>

        <button className="logout" onClick={handleLogOut}>
          LogOut
        </button>
      </header>

      <Outlet />

      <Margin />
    </>
  );
};

export default Navbar;
