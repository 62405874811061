import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import "./adminCoupon.css";
import { IoCloseCircleOutline } from "react-icons/io5";
import { MdEditSquare } from "react-icons/md";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import bg_coupon from "../../../../components/img/bg_coupon.webp";
import Pagination from "./Pagination";

const AdminCoupon = () => {
  const [coupons, setCoupons] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDisabledSubmit, setDisabledSubmit] = useState(false);
  const [mainSiteUrl, setMainSiteUrl] = useState("");
  const navigate = useNavigate();

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchSettings = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/settings.php`,
        formDataPages
      );

      setMainSiteUrl(response.data.url_website);
    } catch (error) {
      console.error("Error:", error);
    }
  }, [validation, LinkLikeBachend]);

  const fetchCoupons = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/coupons.php`,
        formDataPages
      );

      setCoupons(response.data);

      setTotalPage(response.data[0]?.totalPage);
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  const handleLoadMore = async (pageNumber) => {
    const formDataViewCouponsPage = new FormData();
    formDataViewCouponsPage.append("validation", validation);
    formDataViewCouponsPage.append("page", pageNumber);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/coupons.php`,
        formDataViewCouponsPage
      );

      // console.log(response.data);

      setCoupons(response.data);
      setCurrentPage(pageNumber);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchSettings();
    fetchCoupons();
  }, [validation, LinkLikeBachend]);

  const handleDeleteCoupon = async (couponId) => {
    setDisabledSubmit(true);

    const FormDataSubmit = new FormData();
    FormDataSubmit.append("validation", validation);
    FormDataSubmit.append("couponId", couponId);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}delete/delete_coupons.php`,
        FormDataSubmit
      );

      setDisabledSubmit(false);

      if (response.data == "successfully") {
        toast.success("تم الحذف بنجاح");
        setDisabledSubmit(false);

        fetchCoupons();
      } else {
        toast.error("حدث خطأ الحذف");
        console.log(response.data);
        setDisabledSubmit(false);
      }
    } catch (error) {
      setDisabledSubmit(false);
      console.error("Error:", error);
      toast.error("حدث خطأ الحذف");
    }
  };

  const handleEditCoupon = (couponId) => {
    navigate(`/dashboard/edit-coupon/${couponId}`);
  };

  const handleViewCoupon = (urlCoupon) => {
    window.open(`${mainSiteUrl}/coupon/${urlCoupon}`, "_blank");
  };

  // console.log(coupons);
  // console.log(couponsCount);

  return (
    <div className="admin_coupon">
      {coupons?.map((coupon, i) => (
        <div className="card" key={i}>
          <img src={bg_coupon} className="background" alt="bg_coupon" />

          <div className="info">
            <p className="main-text">{coupon.title}</p>
            <div className="cloud">
              <img
                src={`${LinkLikeBachend}${coupon.store_img}`}
                alt={coupon.title}
              />
            </div>
          </div>

          <div className="info">
            <div className="info-left">
              <p className="text-gray">{coupon.clicks_copying_code} نقرة</p>
              <p>{coupon.store_name}</p>
            </div>
            <p className="info-right">
              <FaEye onClick={() => handleViewCoupon(coupon.url_coupon)} />

              <MdEditSquare onClick={() => handleEditCoupon(coupon.id)} />

              <IoCloseCircleOutline
                className="delete_coupon"
                onClick={() => handleDeleteCoupon(coupon.id)}
                disabled={isDisabledSubmit}
                style={{ opacity: isDisabledSubmit ? 0.3 : "" }}
              />
            </p>
          </div>
        </div>
      ))}

      <div className="pagination">
        <Pagination
          currentPage={currentPage}
          totalPage={totalPage}
          onPageChange={handleLoadMore}
        />
      </div>
    </div>
  );
};

export default AdminCoupon;
