import React, { useCallback, useEffect, useState } from "react";
import "./slider.css";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { IoCloseCircleOutline } from "react-icons/io5";
import upsliderImg from "../../../components/img/category_img.webp";

const Slider = () => {
  const { handleSubmit, control, reset } = useForm();
  const [isDisabledSubmit, setDisabledSubmit] = useState(false);
  const [Slider, setSlider] = useState([]);
  const [mainSiteUrl, setMainSiteUrl] = useState("");

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchSettings = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/settings.php`,
        formDataPages
      );

      setMainSiteUrl(response.data.url_admin);
    } catch (error) {
      console.error("Error:", error);
    }
  }, [validation, LinkLikeBachend]);

  const fetchSlider = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/slider.php`,
        formDataPages
      );

      setSlider(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, [validation, LinkLikeBachend]);

  useEffect(() => {
    fetchSlider();
    fetchSettings();
  }, [fetchSettings, fetchSlider]);

  const onSubmitSlider = async (data) => {
    setDisabledSubmit(true);

    const FormDataSubmit = new FormData();
    FormDataSubmit.append("validation", validation);

    Object.entries(data).forEach(([key, value]) => {
      FormDataSubmit.append(key, value);
    });

    try {
      const response = await axios.post(
        `${LinkLikeBachend}create/slider.php`,
        FormDataSubmit
      );

      setDisabledSubmit(false);

      if (response.data == "successfully") {
        toast.success("تم الاضافة بنجاح");
        setDisabledSubmit(false);

        let slider_img = document.querySelector("#slider_img");
        slider_img.value = "";

        reset();

        fetchSlider();
      } else {
        toast.error("حدث خطأ عند الاضافة");
        console.log(response.data);
        setDisabledSubmit(false);
      }
    } catch (error) {
      setDisabledSubmit(false);
      console.error("Error:", error);
      toast.error("حدث خطأ عند الاضافة");
    }
  };

  const handleDeleteslider = async (idSlider) => {
    setDisabledSubmit(true);

    const FormDataSubmit = new FormData();
    FormDataSubmit.append("validation", validation);
    FormDataSubmit.append("idSlider", idSlider);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}delete/delete_slider.php`,
        FormDataSubmit
      );

      setDisabledSubmit(false);

      if (response.data == "successfully") {
        toast.success("تم الحذف بنجاح");
        setDisabledSubmit(false);

        fetchSlider();
      } else {
        toast.error("حدث خطأ الحذف");
        console.log(response.data);
        setDisabledSubmit(false);
      }
    } catch (error) {
      setDisabledSubmit(false);
      console.error("Error:", error);
      toast.error("حدث خطأ الحذف");
    }
  };

  // console.log(Slider);
  // console.log(mainSiteUrl);

  return (
    <div className="slider">
      <form
        onSubmit={handleSubmit(onSubmitSlider)}
        encType="multipart/form-data"
      >
        <h2>اضافة سلايدر</h2>
        <p>يوصي بمساحة صورة 1280 عرض في 500 طول</p>

        <Controller
          name="slider_img"
          control={control}
          defaultValue=""
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <div className="slider_img">
              <input
                required
                id="slider_img"
                type="file"
                accept="image/*"
                onChange={(e) => {
                  onChange(e.target.files[0]);
                }}
                onBlur={onBlur}
                ref={ref}
              />
              {value ? (
                <img src={URL.createObjectURL(value)} alt="slider_img" />
              ) : (
                <img
                  src={upsliderImg}
                  alt="slider_img"
                  className="slider_img"
                />
              )}
            </div>
          )}
        />

        <div>
          <label>اضافة رابط</label>
          <Controller
            name="link_slider"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <input required type="url" placeholder="اضافة رابط" {...field} />
            )}
          />
        </div>

        <button
          type="submit"
          disabled={isDisabledSubmit}
          style={{ opacity: isDisabledSubmit ? 0.3 : "" }}
        >
          حفظ
        </button>
      </form>

      <table>
        <thead>
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {Slider?.map((slider, i) => (
            <tr key={i}>
              <td>
                <a href={slider.link_slider} target="_blank">
                  <img
                    src={`${mainSiteUrl}/${slider.img_slider}`}
                    alt="img_slider"
                  />
                </a>
              </td>
              <td>
                <IoCloseCircleOutline
                  onClick={() => handleDeleteslider(slider.id)}
                  disabled={isDisabledSubmit}
                  style={{ opacity: isDisabledSubmit ? 0.3 : "" }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Slider;
