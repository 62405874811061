import React, { useCallback, useEffect, useState } from "react";
import "./adminContact.css";
import { toast } from "react-toastify";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import upCategoryImg from "../../../components/img/category_img.webp";
import { IoCloseCircleOutline } from "react-icons/io5";
import ViewContact from "./ViewContact";

const AdminContact = () => {
  const { handleSubmit, control, reset } = useForm();

  const [viewsSocialMedia, setViewSocialMedia] = useState([]);

  const [isDisabledSubmit, setDisabledSubmit] = useState(false);
  const [openViewContact, setOpenViewContact] = useState(false);

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchSetSocialMedia = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/social_media.php`,
        formDataPages
      );

      setViewSocialMedia(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, [validation, LinkLikeBachend]);

  const onSubmit = async (data) => {
    setDisabledSubmit(true);

    const FormDataSubmit = new FormData();
    FormDataSubmit.append("validation", validation);

    Object.entries(data).forEach(([key, value]) => {
      FormDataSubmit.append(key, value);
    });

    try {
      const response = await axios.post(
        `${LinkLikeBachend}create/social_media.php`,
        FormDataSubmit
      );

      if (response.data == "successfully") {
        toast.success("تم اضافة البيانات بنجاح");

        setDisabledSubmit(false);

        fetchSetSocialMedia();

        const social_media_image =
          document.getElementById("social_media_image");
        social_media_image.value = "";

        reset();
      } else {
        toast.error("خطأ في اضافة البيانات");

        console.log(response.data);
        setDisabledSubmit(false);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("خطأ في اضافة البيانات");
    }
  };

  useEffect(() => {
    fetchSetSocialMedia();
  }, []);

  const handleDeleteSocialMedia = async (idSocialMedia) => {
    setDisabledSubmit(true);

    const FormDataSubmit = new FormData();
    FormDataSubmit.append("validation", validation);
    FormDataSubmit.append("idSocialMedia", idSocialMedia);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}delete/delete_social_media.php`,
        FormDataSubmit
      );

      setDisabledSubmit(false);

      if (response.data == "successfully") {
        toast.success("تم الحذف بنجاح");
        setDisabledSubmit(false);

        fetchSetSocialMedia();
      } else {
        toast.error("حدث خطأ الحذف");
        console.log(response.data);
        setDisabledSubmit(false);
      }
    } catch (error) {
      setDisabledSubmit(false);
      console.error("Error:", error);
      toast.error("حدث خطأ الحذف");
    }
  };

  const handelOpenMessage = () => {
    setOpenViewContact(true);
  };

  // console.log(contactCount);
  // console.log(viewContact);
  // console.log(viewsSocialMedia);

  return (
    <>
      <div className="social_media_admin">
        <h2>اضافة روابط التواصل الاجتماعي</h2>
        <form
          className="social_media_admin_form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="data_form">
            <Controller
              name="social_media_image"
              control={control}
              defaultValue=""
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <div className="social_media_image">
                  <input
                    required
                    id="social_media_image"
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      onChange(e.target.files[0]);
                    }}
                    onBlur={onBlur}
                    ref={ref}
                  />
                  {value ? (
                    <img
                      src={URL.createObjectURL(value)}
                      alt="social_media_image"
                    />
                  ) : (
                    <img src={upCategoryImg} alt="social_media_image" />
                  )}
                </div>
              )}
            />

            <Controller
              name="social_media_link"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input
                  required
                  type="url"
                  {...field}
                  placeholder="رابط التواصل"
                />
              )}
            />
          </div>

          <input
            type="submit"
            value="اضافة"
            disabled={isDisabledSubmit}
            style={{ opacity: isDisabledSubmit ? 0.3 : 1 }}
          />
        </form>

        <table>
          <tbody>
            {viewsSocialMedia.length > 0 ? (
              viewsSocialMedia.map((item, i) => (
                <tr key={i}>
                  <td>
                    <a
                      href={item.social_media_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={`${LinkLikeBachend}${item.social_media_image}`}
                        alt={item.social_media_link}
                      />
                    </a>
                  </td>
                  <td>
                    <IoCloseCircleOutline
                      onClick={() => handleDeleteSocialMedia(item.id)}
                      disabled={isDisabledSubmit}
                      style={{ opacity: isDisabledSubmit ? 0.3 : "" }}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2">لا يوجد روابط تواصل</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <button className="openViewContact" onClick={handelOpenMessage}>
        عرض أحدث الرسائل
      </button>

      {openViewContact && <ViewContact close={setOpenViewContact} />}
    </>
  );
};

export default AdminContact;
