import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import "./loginAdmin.css";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuthAdmin } from "../components/context/AuthAdmin";
import { useNavigate } from "react-router-dom";

const LoginAdmin = () => {
  const { handleSubmit, control } = useForm();
  const AuthAdmin = useAuthAdmin();
  const navigate = useNavigate();

  useEffect(() => {
    if (AuthAdmin.admin == "admin") {
      navigate("/dashboard", { replace: true });
    }
  }, []);

  const [isDisabledLogin, setDisabledLogin] = useState(false);
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const onSubmit = async (data) => {
    setDisabledLogin(true);

    const formDataAdmin = new FormData();
    formDataAdmin.append("validation", validation);
    formDataAdmin.append("password_admin", data.password);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/login_admin.php`,
        formDataAdmin
      );

      if (response.data == "admin") {
        AuthAdmin.loginAdmin(response.data);

        toast.success("تم تسجيل الدخول بنجاح");

        navigate("/dashboard", { replace: true });
      } else if (response.data == "incorrect") {
        toast.error("كلمة المرور غير صحيحة");
        setDisabledLogin(false);
      }
    } catch (error) {
      setDisabledLogin(false);
      console.error("Error:", error);
      toast.error("خطأ عند ارسال البيانات");
    }
  };

  // console.log(AuthAdmin);

  return (
    <div className="login_admin">
      <div className="box">
        <span className="borderLine"></span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2>Sign In Admin</h2>

          <div className="inputBox">
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input type="password" required {...field} />
              )}
            />

            <span>Password</span>
            <i></i>
          </div>

          <input
            type="submit"
            style={{ opacity: isDisabledLogin ? 0.3 : 1 }}
            value="Login"
            disabled={isDisabledLogin}
          />
        </form>
      </div>
    </div>
  );
};

export default LoginAdmin;
