import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import upCategoryImg from "../../../components/img/category_img.webp";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "./adminSettings.css";

const AdminSettings = () => {
  const { handleSubmit, control, setValue } = useForm();
  const [isDisabledSubmit, setDisabledSubmit] = useState(false);
  const [settings, setSettings] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchSettings = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/settings.php`,
        formDataPages
      );

      setSettings(response.data);

      setValue("settings_title", response.data.title);
      setValue("description_seo", response.data.description_seo);
      setValue("keywords", response.data.keywords);
      setValue("html_head", response.data.html_head);
      setValue("url_website", response.data.url_website);
      setValue("url_admin", response.data.url_admin);
    } catch (error) {
      console.error("Error:", error);
    }
  }, [validation, LinkLikeBachend]);

  useEffect(() => {
    fetchSettings();
  }, []);

  const onSubmitSettings = async (data) => {
    setDisabledSubmit(true);

    const FormDataSubmit = new FormData();
    FormDataSubmit.append("validation", validation);

    Object.entries(data).forEach(([key, value]) => {
      FormDataSubmit.append(key, value);
    });

    try {
      const response = await axios.post(
        `${LinkLikeBachend}update/settings.php`,
        FormDataSubmit
      );

      setDisabledSubmit(false);

      if (response.data == "successfully") {
        toast.success("تم التحديث بنجاح");
        setDisabledSubmit(false);

        fetchSettings();

        setValue("change_password", "");
      } else {
        toast.error("حدث خطأ عند التحديث");
        console.log(response.data);
        setDisabledSubmit(false);
      }
    } catch (error) {
      setDisabledSubmit(false);
      console.error("Error:", error);
      toast.error("حدث خطأ عند التحديث");
    }
  };

  return (
    <div className="settings">
      <form
        onSubmit={handleSubmit(onSubmitSettings)}
        encType="multipart/form-data"
      >
        <h2>الاعدادات</h2>

        <div>
          <b>Favicon</b>
          <Controller
            name="settings_icon"
            control={control}
            defaultValue=""
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <div className="settings_icon">
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    onChange(e.target.files[0]);
                  }}
                  onBlur={onBlur}
                  ref={ref}
                />
                {value || settings.icon ? (
                  <img
                    src={
                      value
                        ? URL.createObjectURL(value)
                        : `${LinkLikeBachend}${settings.icon}`
                    }
                    alt={settings.title}
                  />
                ) : (
                  <img src={upCategoryImg} alt={settings.title} />
                )}
              </div>
            )}
          />
        </div>

        <div>
          <b>Logo</b>

          <Controller
            name="settings_logo"
            control={control}
            defaultValue=""
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <div className="settings_logo">
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    onChange(e.target.files[0]);
                  }}
                  onBlur={onBlur}
                  ref={ref}
                />
                {value || settings.logo ? (
                  <img
                    src={
                      value
                        ? URL.createObjectURL(value)
                        : `${LinkLikeBachend}${settings.logo}`
                    }
                    alt={settings.title}
                  />
                ) : (
                  <img src={upCategoryImg} alt={settings.title} />
                )}
              </div>
            )}
          />
        </div>

        <div>
          <b dir="rtl">عنوان SEO ينصح 50-60 حرفًا</b>
          <Controller
            name="settings_title"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <input type="text" placeholder="عنوان الموقع" {...field} />
            )}
          />
        </div>

        <div>
          <b dir="rtl">وصف SEO ينصح 150 إلى 160 حرفًا</b>
          <Controller
            name="description_seo"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <textarea placeholder="وصف الموقع" {...field}></textarea>
            )}
          />
        </div>

        <div>
          <b dir="rtl">الكلمات المفتاحية مفصولة بـ ( , ) بين كل كلمة</b>
          <Controller
            name="keywords"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <input type="text" placeholder="الكلمات المفتاحية" {...field} />
            )}
          />
        </div>

        <div>
          <b>url website</b>
          <Controller
            name="url_website"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <input type="url" placeholder="رابط الموقع" {...field} />
            )}
          />
        </div>

        <div>
          <b>url img</b>
          <Controller
            name="url_admin"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <input type="url" placeholder="رابط الصور" {...field} />
            )}
          />
        </div>

        <div>
          <b>Head</b>
          <Controller
            name="html_head"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <textarea type="text" placeholder="Head" {...field}></textarea>
            )}
          />
        </div>

        <div className="change_password_profile">
          <Controller
            name="change_password"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <>
                <input
                  {...field}
                  type={showPassword ? "text" : "password"}
                  placeholder="Change password"
                />
                <span onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </span>
              </>
            )}
          />
        </div>

        <button
          type="submit"
          disabled={isDisabledSubmit}
          style={{ opacity: isDisabledSubmit ? 0.3 : "" }}
        >
          حفظ
        </button>
      </form>
    </div>
  );
};

export default AdminSettings;
