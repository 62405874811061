import React, { useCallback, useEffect, useState } from "react";
import "./adminDataPages.css";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";

const AdminDataPages = () => {
  const { handleSubmit, control, setValue } = useForm();

  const [isDisabledSubmit, setDisabledSubmit] = useState(false);

  const [editorConfigAbout] = useState({
    placeholder: "",
    minHeight: "500",
  });
  const [editorConfigPrivacyPolicy] = useState({
    placeholder: "",
    minHeight: "500",
  });

  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;

  const fetchDataPages = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);
    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/view_data_pages.php`,
        formDataPages
      );
      setValue("about", response.data.about || "");
      setValue("privacy_policy", response.data.privacy_policy || "");
    } catch (error) {
      console.error("Error:", error);
    }
  }, [LinkLikeBachend, validation]);

  useEffect(() => {
    fetchDataPages();
  }, [fetchDataPages]);

  const onSubmit = async (data) => {
    if (data.about.length > 0 && data.privacy_policy.length > 0) {
      setDisabledSubmit(true);
      const FormDataSubmit = new FormData();
      FormDataSubmit.append("validation", validation);
      FormDataSubmit.append("about", data.about);
      FormDataSubmit.append("privacy_policy", data.privacy_policy);

      try {
        const response = await axios.post(
          `${LinkLikeBachend}update/data_pages.php`,
          FormDataSubmit
        );

        if (response.data == "successfully") {
          toast.success("تم تحديث البيانات بنجاح");
          setDisabledSubmit(false);
          fetchDataPages();
        } else {
          toast.error("خطأ في تحديث البيانات");
          console.log(response.data);
          setDisabledSubmit(false);
        }
      } catch (error) {
        setDisabledSubmit(false);
        console.error("Error:", error);
        toast.error("خطأ عند ارسال البيانات");
      }
    } else {
      toast.error("حقل فارغ");
    }
  };

  // console.log(dataAbout);

  return (
    <div className="about_admin">
      <form
        className="about_form"
        onSubmit={handleSubmit(onSubmit)}
        encType="multipart/form-data"
      >
        <div className="data_form ar">
          <div className="description">
            <b>من نحن</b>

            <Controller
              name="about"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <JoditEditor config={editorConfigAbout} {...field} />
              )}
            />
          </div>
        </div>

        <div className="data_form ">
          <div className="description">
            <b>سياسة الخصوصية</b>

            <Controller
              name="privacy_policy"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <JoditEditor config={editorConfigPrivacyPolicy} {...field} />
              )}
            />
          </div>
        </div>

        <input
          type="submit"
          value="حفظ"
          disabled={isDisabledSubmit}
          style={{ opacity: isDisabledSubmit ? 0.3 : 1 }}
        />
      </form>
    </div>
  );
};

export default AdminDataPages;
