import React, { useCallback, useEffect, useState } from "react";
import "../categories.css";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import upCategoryImg from "../../../../components/img/category_img.webp";
import { useParams } from "react-router-dom";

const EditCategory = () => {
  const { idCategory } = useParams();
  const LinkLikeBachend = process.env.REACT_APP_BACKEND_URL;
  const validation = process.env.REACT_APP_VALIDATION;
  const [category, setCategory] = useState({});
  const { handleSubmit, control, setValue, getValues, reset } = useForm();
  const [isDisabledSubmit, setDisabledSubmit] = useState(false);

  const fetchCategories = useCallback(async () => {
    const formDataPages = new FormData();
    formDataPages.append("validation", validation);
    formDataPages.append("idCategory", idCategory);

    try {
      const response = await axios.post(
        `${LinkLikeBachend}read/category.php`,
        formDataPages
      );

      setCategory(response.data);

      setValue("category_name", response.data.category_name);
      setValue("category_url", response.data.category_url);
    } catch (error) {
      console.error("Error:", error);
    }
  }, [validation, LinkLikeBachend]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const onBlurCategoryName = () => {
    const firstNameValue = getValues("category_name");
    const trimmedLastName = firstNameValue.trim().replace(/^-+|-+$/g, "");
    const modifiedFirstName = trimmedLastName
      .replace(/\s+/g, "-")
      .toLowerCase();
    setValue("category_url", modifiedFirstName);
  };

  const onBlurCategoryUrl = () => {
    const lastNameValue = getValues("category_url");
    const trimmedLastName = lastNameValue.trim().replace(/^-+|-+$/g, "");
    const modifiedLastName = trimmedLastName.replace(/\s+/g, "-").toLowerCase();
    setValue("category_url", modifiedLastName);
  };

  const onSubmitCategories = async (data) => {
    setDisabledSubmit(true);

    const FormDataSubmit = new FormData();
    FormDataSubmit.append("validation", validation);
    FormDataSubmit.append("idCategory", idCategory);

    Object.entries(data).forEach(([key, value]) => {
      FormDataSubmit.append(key, value);
    });

    try {
      const response = await axios.post(
        `${LinkLikeBachend}update/category.php`,
        FormDataSubmit
      );

      setDisabledSubmit(false);

      if (response.data == "successfully") {
        toast.success("تم التحديث بنجاح");
        setDisabledSubmit(false);

        let category_img = document.querySelector("#category_img");
        category_img.value = "";

        fetchCategories();
      } else if (response.data == "redundant") {
        toast.error("رابط التصنيف موجود بالفعل");
        setDisabledSubmit(false);
      } else {
        toast.error("حدث خطأ عند الاضافة");
        console.log(response.data);
        setDisabledSubmit(false);
      }
    } catch (error) {
      setDisabledSubmit(false);
      console.error("Error:", error);
      toast.error("حدث خطأ عند الاضافة");
    }
  };

  //   console.log(category);

  return (
    <div className="categories">
      <form
        onSubmit={handleSubmit(onSubmitCategories)}
        encType="multipart/form-data"
      >
        <h2>تعديل تصنيف</h2>

        <Controller
          name="category_img"
          control={control}
          defaultValue=""
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <div className="category_img">
              <input
                id="category_img"
                type="file"
                accept="image/*"
                placeholder="اسم التصنيف"
                onChange={(e) => {
                  onChange(e.target.files[0]);
                }}
                onBlur={onBlur}
                ref={ref}
              />
              {value || category.category_img ? (
                <img
                  src={
                    value
                      ? URL.createObjectURL(value)
                      : `${LinkLikeBachend}${category.category_img}`
                  }
                  alt="category_img"
                />
              ) : (
                <img src={upCategoryImg} alt="category_img" />
              )}
            </div>
          )}
        />

        <div>
          <label>اسم التصنيف</label>
          <Controller
            name="category_name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <input
                required
                type="text"
                placeholder="اسم التصنيف"
                {...field}
                onBlur={onBlurCategoryName}
              />
            )}
          />
        </div>

        <div>
          <label>رابط التصنيف</label>
          <Controller
            name="category_url"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <input
                required
                type="text"
                {...field}
                onBlur={onBlurCategoryUrl}
              />
            )}
          />
        </div>

        <button
          type="submit"
          disabled={isDisabledSubmit}
          style={{ opacity: isDisabledSubmit ? 0.3 : "" }}
        >
          حفظ
        </button>
      </form>
    </div>
  );
};

export default EditCategory;
